.root {
  height: 100vh;
  width: 100%;
  display: flex;
}

.karticka {
  width: 45vw;
  height: 50vh;
  margin: auto;
  background-color: white;
  box-shadow: 9px 8px 6px #00000029;
}

//main
.karticka-main {
  display: flex;
}

.main-row {
  margin: 0 !important;
  width: 100%;
}

.main-headshot {
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 0 !important;
  padding-left: 10% !important;
}

.main-headshot img {
  width: 15vw;
  height: 15vw;
}

.main-text {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 0 !important;
  padding-right: 10% !important;
  font-size: 1.5rem;
}

.main-text h1 {
  padding-bottom: 10%;
  font-size: 3rem;
}

.main-text p {
  color: #707070;
}

//ome
.karticka-ome {
  padding: 0 4rem;
  font-size: 2vh;
  line-height: 1.2em;
  text-align: justify;
  display: flex;
  flex-flow: column;
  justify-content: center;
  color: #707070;
}

.karticka-ome p {
  padding: 1rem 0;
}

//dovednosti
.karticka-dovednosti {
  font-size: 2vh;
  color: #707070;
}

.karticka-dovednosti h3 {
  color: black;
  text-align: center;
}

.karticka-dovednosti li {
  text-align: left;
  padding-left: 33%;
}

//projekty
.boxik {
  margin: 3rem;
  height: 100%;
  border-radius: 4px;
  text-align: center;
  color: #707070;
  text-decoration: none !important;
}

.boxik:hover {
  text-decoration: none !important;
}

.boxik h5 {
  color: black;
}

.carousel-own {
  height: 50vh;
}

.panel {
  height: 45vh;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  margin: 0 5px;
  text-align: center;
  color: black;
}

.panel-img {
  width: 80%;
  border-radius: 0.5rem;
}

.panel-subinfo {
  font-size: small;
  color: grey;
}

.panel0 {
  //background-color: #82c4c3;
}
.panel1 {
  // background-color: #f9d89c;
}
.panel2 {
  //background-color: #f5a7a7;
}

//kontakty
.karticka-kontakty {
  color: #707070;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
}
.karticka-kontakty span {
  margin: 0 auto;
  padding: 1rem 0;
  font-size: 1.75rem;
}
