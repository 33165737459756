:root {
  --color-pastel-lightblue: #0ACBCB;
  --color-pastel-blue: #04B3E4;
  --color-pastel-purple: #62206A;
  --color-pastel-green: #015551;
  --color-pastel-yellow: #FFC026;
  --color-pastel-black: #010101;
}

.strana {
  background-color: #F0F0F2;
  text-align: center;
  color: #707070;
}

b {
  color: black;
}

.strana p {
  padding: 0 10vw;
}

.strana4 {
  text-align: center;
}

.strana h3 {
  padding-top: 5vh;
}

.mobile-navigace {
  width: 100vw;
  position: absolute;
  bottom: 2%;
  text-align: center;
}

.tlacitko {
  color: grey;
  border: none;
  font-size: small;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #F0F0F2;
}

.tlacitko-selected {
  color: black;
  text-decoration: underline black;
}

//main
.strana0 {
  img {
    margin-top: -5rem;
    width: 60vw;
    height: 60vw;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-weight: bold;
      padding-top: 2rem;
      color: black;
    }
  }
}

//ome
.strana1 {
  h3 {
    color: black;
    font-weight: bold;
  }
  &__content {
    height: 80%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    text-align: justify-all;
    span {
      padding: 1rem 0;
    }
    svg {
      width: 10vw;
      height: 10vw;
      padding: 0 1vw;
    }
  }
}

//projekty

.projects-control {
  border: none;
  background-color: #F0F0F2;
  font-size: 40px;
  padding: 10px;
}

.projects-align {
  margin: auto;
}

.projects-stranky {

}

.projects-img {
  padding-top: 16vh;
}

.mobile-panel-subinfo {
  color: grey;
}

.mobile-avatar {
  padding: 10vh;
}


