:root {
  --view-width: 100vh;
  --color-bg: #F0F0F2;
  --color-menu-selected: #000000;
  --color-menu-unselected: #707070;
  --icon-radius: 60px;
  --font-size-nav: 30px;
  --half-view-width: calc(var(--view-width) / 2);
  --navbar-position-1: calc(var(--half-view-width) - 15px);
  --navbar-position-2: calc(var(--navbar-position-1) - 70px);
  --navbar-position-3: calc(var(--navbar-position-2) - 70px);
  --navbar-position-4: calc(var(--navbar-position-3) - 70px);
}

.desktop-root {
  display: flex;
  background-color: var(--color-bg);
  height: 500vh;
  font-family: "Helvetica CE 55 Roman", sans-serif;
}

.column {
  height: 100vh;
}

.navigace {
  width: 25vw;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.content {
  width: 50vw;
}

.right {
  width: 25vw;
  display: flex;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}


//navigaceeeee
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.navigace-wrap {
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.navigace-status {
  margin-top: calc(50vh - 35px);
  padding-right: 1vw;
  font-size: 40px;
  font-weight: bold;
  font-family: 'Comic Neue', cursive;
}

.navigace-root {
  margin-top: var(--navbar-position-1);
  font-size: var(--font-size-nav);
  transition: margin-top 10ms;
}

.navigace-item {
  color: var(--color-menu-unselected);
  transition: color 500ms;
  cursor: pointer;
}

.navigace-root > a:hover {
  text-decoration: underline;
}

.margin-between {
  margin: 0 0 35px 0;
}

//sidebaaaaaaaaaaaaaaaar

.sidebar-root {
  margin: auto;
}

.sidebar-root svg {
  width: var(--icon-radius);
  height: var(--icon-radius);
}

.sidebar-root a {
  color: black;
  text-decoration: none;
  font-size: x-large;
  vertical-align: middle;
}

.sidebar-root a:hover {
  color: black;
}

.sidebar-link {
  padding: 5vh 0;
}

.sidebar-link-text {
  padding-left: 1vw;
}

a:hover {
  color: black !important;
}
